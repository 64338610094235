// SCSS Variables
$link-color: #43b1b9;
$background-dark: #1e1e1e;
$background-middle: #1e1e1e;
$background-light: #f8f8f9;
$text-light: #f2f2f3;
$text-middle: #242423;
$text-dark: #191919;
$border-orange: #e35615;
// $font-title: Merriweather, serif;
/* look into garamond, freight text, tiempos text, gt sectra  */
$font-base: "Source Sans Pro", sans-serif;
/* Look into futura, open sans, proxima nova, avenir,brandon grotesque, graphik */

// Making a mixin
// call it with @include setGridItems
@mixin setGridItems {
  .grid-item-1 {
    grid-area: one;
  }
  .grid-item-2 {
    grid-area: two;
  }
  .grid-item-3 {
    grid-area: three;
  }
  .grid-item-4 {
    grid-area: four;
  }
  .grid-item-5 {
    grid-area: five;
  }
  .grid-item-6 {
    grid-area: six;
  }
}

/* base reset so that I can set the padding and margins*/
/* Generally, I'll make 
  padding- 10px 15px
  and margin - 20px */
* {
  margin: 0;
  padding: 0;
  /* max-width: 2000px; */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Setting the base properties for the entire page */
/* box-sizing so that everything will contain its internal elements*/
html,
body {
  background-color: $background-dark;
  color: #191919;
  font-size: 1rem;
  font-family: "Source Sans Pro", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin-left: auto;
  margin-right: auto; /*This will make it so when you click on a link, 
    it will scroll to that spot rather than jump*/
}

// ------ Temporary Text Color other than intro

.about,
.home {
  color: whitesmoke;
}

/* base heading properties*/
/* each section's heading is individual */
h1,
h2 {
  font-family: Merriweather, serif;
  text-align: center;
}

#introduction-section,
#portfolio-section,
#contact-section,
#about-section {
  padding: 10px 15px;
  width: 100%;
}

// ------------------Navbar Section ------------------------------------------------------------

.nav {
  padding: 10px 15px;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $background-dark;

  z-index: 500; /*This makes sure that the nav is always visible on top of any other element*/
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

/*------ Navbar CSS------ */

nav {
  a {
    text-decoration: none;
    color: $text-light;
    font-size: 1.2rem;
    &:hover {
      // font-weight: 550;
      color: $link-color;
      cursor: pointer;
    }
  }
  #left-nav,
  #right-nav {
    display: flex;
    flex-direction: row;
  }
  #left-nav {
    padding-left: 1rem;
  }
  #right-nav {
    justify-content: space-around;
    width: 45vw;
  }

  // Drop down button CSS
  .fas {
    color: $text-light;
    background-color: $background-dark;
  }
  #dropdown-button {
    color: $text-light;
    font-size: 1.4rem;
    background-color: $background-dark;
    padding: 0 1rem;
    border: none;
    display: none;
  }
}

// ----------------- Media Calls for Navbar---------------------

/* -----------Phone Only Navbar (0px-599px)-----------*/

@media (max-width: 599px) {
  .nav {
    flex-direction: column;

    #left-nav {
      justify-content: space-between;
      //   width: 100%;
      //   flex-direction: row;
    }
    #right-nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      //   display: none;
    }
    // this shows the button for the drop-down menu
    #dropdown-button {
      display: inline-block;
    }
    #dropdown-button:hover {
      cursor: pointer;
    }
  }
  /* this shows the menu, is gets added to div#right-nav when the side button is clicked */
  #right-nav.dropdown {
    display: flex;
    flex-direction: column;
    padding: 0.3rem 1rem 0.3rem 0;
  }

  // Customizing the menu
  #right-nav a {
    text-align: right;
    width: 100%;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.35rem;
  }
  #right-nav a:hover div {
    color: $link-color;
  }
}

/* ______________________________________________________________________ */
/* ---------------------- BEGIN INTRO SECTION --------------------*/

#introduction-section {
  padding-top: 3rem;
  transition: height 0.4s linear;
  height: 29rem;
  color: $text-middle;
  background-color: $background-dark;
  /*This is a fallback if background-image doesn't load */

  background-image: url("./images/background3a.jpg");

  background-size: cover;
  background-repeat: no-repeat;
}

/* This is a container holding the circle and its text */

.intro-text-wrapper {
  transition: height 0.4s linear;
  transition: width 0.4s linear;
  transition: margin 0.4s linear;
  height: 30rem;
  width: 29rem;
  padding: 10px;
  margin-top: 8rem;
  margin-left: 8rem;
  border-radius: 100%; /*This makes it a circle*/
  display: block;
  // background-color: #f8a883;
  // background-color: #f39274;
  // background-color: #ee8363;
  background-color: #f39c74;

  box-shadow: rgba(0, 0, 0, 0.39) 0px 2px 8px;

  /* other colors I considered were #f6b597;,#f8b08f;, and #242423; */
}

#introduction-section h1 {
  margin-top: 9rem;
  font-size: 1.8rem;
}
#introduction-section h2 {
  font-size: 1.4rem;
  margin: 1.5rem;
}

// ---------------------Responsive Intro SCSS------------------

/* -------------------------Phone Only (0px-599px)---------------------*/

@media (max-width: 599px) {
  #introduction-section {
    background-image: url("./images/background3smalld.jpg");

    .intro-text-wrapper {
      transition: margin 0.4s linear;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15vw;
      margin-bottom: 12vw;
      transition: height 0.4s linear;
      transition: width 0.4s linear;
      height: 300px;
      width: 300px;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    h1 {
      margin-top: 0;
      font-size: 1.2rem;
    }
    h2 {
      font-size: 0.9rem;
      margin: 0.5rem;
    }
  }
}
/* Iphone 0-350px */
@media (max-width: 360px) {
  #introduction-section {
    transition: height 0.4s linear;
    height: 25rem;

    .intro-text-wrapper {
      transition: height 0.4s linear;
      transition: width 0.4s linear;
      height: 75vw;
      width: 75vw;
      max-height: 250px;
      max-width: 250px;
      padding-top: 10px;
    }
    // Intro section headings
    h1 {
      margin-top: 0;
      font-size: 0.95rem;
      font-weight: 600;
    }
    h2 {
      font-size: 0.875rem;
      margin: 0.5rem;
      font-weight: 450;
    }
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  #introduction-section {
    .intro-text-wrapper {
      transition: margin 0.4s linear;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  #introduction-section {
    position: relative;
    min-height: 29rem;
    height: 80vh;
    .intro-text-wrapper {
      position: absolute;
      left: 8rem;
      bottom: -12rem;
    }
  }
}

@media (min-width: 1600px) {
  #introduction-section {
    transition: height 0.4s linear;
    min-height: 47rem;
    height: 80vh;
    position: relative;

    .intro-text-wrapper {
      position: absolute;
      height: 36rem;
      width: 36rem;
      left: 10vw;
      bottom: -14rem;
    }

    h1 {
      margin-top: 11rem;
      font-size: 2.2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
}

/*-------------------------END INTRO SECTION------------------------- */
/* __________________________________________________________________ */

/* __________________________________________________________________*/
/* ----------------------BEGIN PORTFOLIO SECTION----------------------------- */

#portfolio-section {
  padding-bottom: 5rem;
  padding-top: 5rem;
  background-color: $background-middle;
  color: $text-light;
  text-align: center;
}

#portfolio-section h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 3rem;
  font-weight: 450;
  padding: 5px;
  margin: 0;
}

/* ------------------------------- */
/* This is the corners on the portfolio h2 */
.portfolio-header-wrapper,
.about-header-wrapper {
  position: relative;
  display: inline-block;
  margin-top: 6rem;
  margin-bottom: 3rem;
  padding: 10px 15px;
}
.top,
.bottom {
  position: absolute;
  width: 30px;
  height: 30px;
}

.top {
  top: 0;
  border-top: 6px solid #050505;
}

.bottom {
  bottom: 0;
  border-bottom: 6px solid #050505;
}

.left {
  left: 0;
  border-left: 6px solid #050505;
}

.right {
  right: 0;
  border-right: 6px solid #050505;
}
/* End of corner styling*/
/* ------------------ */

/* sets the size of the grid and centers it */
.grid-wrapper {
  /* width: 90vw; */
  width: 93.5%;
  margin-left: auto;
  margin-right: auto;
}

/* Setting grid and divisions of grid */
.portfolio-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "one two three"
    "four five six";
  grid-gap: 30px;
  justify-items: center;
  // Label the grid items for IE
  @include setGridItems;
}

/* makes each grid item clickable anywhere  */
.portfolio-grid a {
  text-decoration: none;
  color: $text-light;
}

/* This stylizes the project cells */
.portfolio-example {
  text-align: center;
  padding: 1.3rem 1.3rem 1.5rem 1.3rem;

  background-color: #333333;

  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.39) 0px 2px 8px;
}

.portfolio-example img {
  width: 100%;
  height: 11rem;
  border-radius: 8px;
}

.portfolio-example {
  img.show-on-hover {
    display: none;
  }
}
.portfolio-example {
  &:hover {
    img.hide-on-hover {
      display: none;
    }
    img.show-on-hover {
      display: inline-block;
    }
  }
}

.portfolio-example p.tags {
  text-align: center;
  // color: #f39c74;
  color: $link-color;
  padding-top: 1rem;
}

.portfolio-example h3 {
  padding: 1rem;
  font-weight: 400;
  font-size: 1.5rem;
}

.portfolio-grid a:hover h3 {
  // color: $link-color;
  color: #f39c74;
}

.portfolio-example p {
  text-align: left;
  line-height: 1.3;
}

// ------------------ Portfolio Responsive Media Calls -------------------

@media (max-width: 599px) {
  #portfolio-section {
    padding-top: 1rem;

    .portfolio-grid {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "one"
        "two"
        "three"
        "four"
        "five"
        "six";
      // Label the grid items for IE
      @include setGridItems;
    }

    .portfolio-example {
      padding-bottom: 15px;
      margin-left: auto;
      margin-right: auto;
      max-width: 300px;
    }

    .portfolio-example img {
      width: 100%;
      height: 10rem;
    }

    .portfolio-example p.tags {
      text-align: center;
    }
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  #portfolio-section {
    .portfolio-grid {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "one two"
        "three four"
        "five six";
      // Label the grid items for IE
      @include setGridItems;
    }
    .portfolio-example {
      transition: width 0.4s linear;
      max-width: 300px;
    }
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  #portfolio-section {
    .portfolio-example {
      max-width: 300px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  #portfolio-section {
    position: relative;
    .portfolio-example {
      transition: width 0.4s linear;
      max-width: 350px;
    }
  }
}

@media (min-width: 1600px) {
  #portfolio-section {
    position: relative;
    .portfolio-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 40px;
      grid-template-areas:
        "one two three four"
        "five six . .";
      // Label the grid items for IE
      @include setGridItems;
    }

    p {
      font-size: 1.2rem;
    }
  }
}

// -----------------End Portfolio Section -------------------------------------
// ----------------------------------------------------------------------------------------------

/*------------------------------------------------- Contact Me Section ----------------------------------------------------------------------------*/
#contact-section {
  padding-bottom: 23.28px;
  /*This makes it so the bottom corners don't move when the links are hovered */
  background-color: $background-light;
  text-align: center;
  color: $text-middle;
  font-size: 1.5rem;
}

/* Corners for contact */
.contact-section-wrapper {
  position: relative;
  display: inline-block;
  /* width: 70vw; */
  transition: width 0.4s linear;
  width: 72.5%;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding: 2rem 1rem;
}
.contact-top,
.contact-bottom {
  position: absolute;
  width: 50px;
  height: 50px;
}

/* #e34815; */
.contact-top {
  top: 0;
  border-top: 6px solid $border-orange;
}

.contact-bottom {
  bottom: 0;
  border-bottom: 6px solid $border-orange;
}

.contact-left {
  left: 0;
  border-left: 6px solid $border-orange;
}

.contact-right {
  right: 0;
  border-right: 6px solid $border-orange;
}

#contact-section h2 {
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 1rem;
}

/* the row of contact links */
.link-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
  margin: 1.3rem auto 2rem auto;
}

#contact-section {
  a {
    text-decoration: none;
    color: $background-dark;
    font-size: 1.2rem;
    margin-bottom: 0.35rem;
  }
  .fas,
  .fab {
    color: $background-dark;
    background-color: $background-light;
  }

  a:active,
  a:active .fab,
  a:active .fas {
    color: $background-dark;
    font-size: 1.2rem;
    margin-bottom: 0.35rem;
  }

  a:hover,
  a:hover .fab,
  a:hover .fas {
    color: $link-color;
    // font-size: 1.5rem;
    margin-bottom: 0;
  }
}

// Contact Section Responsive Media Calls

@media (max-width: 599px) {
  #contact-section {
    .contact-section-wrapper {
      width: 93.5%;
    }

    p {
      font-size: 1.2rem;
    }
    .link-box {
      width: 70%;
      margin-top: 1.5rem;
    }
    a {
      font-size: 0.8rem;
    }
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  #contact-section {
    p {
      font-size: 1.3rem;
    }

    .link-box {
      transition: margin 0.4s linear;
      margin-top: 1.5rem;
      width: 60%;
    }
    .contact-section-wrapper {
      width: 93.5%;
    }
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  #contact-section {
    .contact-section-wrapper {
      transition: width 0.4s linear;
      width: 93.5%;
    }
    .link-box {
      width: 40%;
    }
  }
}

@media (min-width: 1600px) {
  #contact-section {
    .link-box {
      width: 35%;
    }
  }
  /* This keeps the social media links from expanding too wide. */
}

// --------------------------------------------------------About Section--------------------------------

#about-section {
  padding-bottom: 5rem;
  padding-top: 5rem;
  background-color: $background-middle;
  color: $text-light;
  color: #f2f2f3c7;
  text-align: center;
}

#about-section h2 {
  font-family: "Source Sans Pro";
  font-size: 3rem;
  font-weight: 450;
  padding: 5px;
  margin: 0;
}

.about-description {
  text-align: left;
  width: 72.5%;
  padding: 2rem 1rem;

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  .description-block-2 {
    margin-top: 2rem;
  }

  h3 {
    font-size: 1.5rem;
    padding: 0.3rem;
    padding-left: 0;
    border-bottom: #e35615 solid 2px;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.2rem;
  }
}

@media (max-width: 599px) {
  .about-description {
    width: 93.5%;
    p {
      font-size: 1rem;
    }
  }
}

// ------------------ About Responsive Media Calls -------------------

@media (max-width: 599px) {
  #about-section {
    padding-top: 1rem;
  }
}

/*---------------------------------------------------------- Footer section-------------------------------------------------------- */

#footer {
  position: relative;
  background-color: $background-dark;
  height: 7rem;
  // color: #717174;
  // color: #a9a9ab;
  color: #8d8d8f;

  .image-reference {
    text-align: right;
    padding: 2rem;
    line-height: 1.4;
  }
  a {
    text-decoration: none;
    // color: #717174;
    color: #8d8d8f;
  }

  i {
    font-size: 0.7rem;
  }
}

// Responsive Footer Media Calls

@media (max-width: 599px) {
  #footer {
    .image-reference {
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 360px) {
  #footer {
    .image-reference {
      font-size: 0.8rem;
    }
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  #contact-section {
    .contact-section-wrapper {
      transition: width 0.4s linear;
      width: 93.5%;
    }
    .link-box {
      width: 40%;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  #footer {
    .image-reference {
      font-size: 1rem;
    }
  }
}
