/* look into garamond, freight text, tiempos text, gt sectra  */
/* Look into futura, open sans, proxima nova, avenir,brandon grotesque, graphik */
/* base reset so that I can set the padding and margins*/
/* Generally, I'll make 
  padding- 10px 15px
  and margin - 20px */
* {
  margin: 0;
  padding: 0;
  /* max-width: 2000px; */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Setting the base properties for the entire page */
/* box-sizing so that everything will contain its internal elements*/
html,
body {
  background-color: #1e1e1e;
  color: #191919;
  font-size: 1rem;
  font-family: "Source Sans Pro", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin-left: auto;
  margin-right: auto; /*This will make it so when you click on a link, 
it will scroll to that spot rather than jump*/
}

.about,
.home {
  color: whitesmoke;
}

/* base heading properties*/
/* each section's heading is individual */
h1,
h2 {
  font-family: Merriweather, serif;
  text-align: center;
}

#introduction-section,
#portfolio-section,
#contact-section,
#about-section {
  padding: 10px 15px;
  width: 100%;
}

.nav {
  padding: 10px 15px;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #1e1e1e;
  z-index: 500; /*This makes sure that the nav is always visible on top of any other element*/
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

/*------ Navbar CSS------ */
nav a {
  text-decoration: none;
  color: #f2f2f3;
  font-size: 1.2rem;
}
nav a:hover {
  color: #43b1b9;
  cursor: pointer;
}
nav #left-nav,
nav #right-nav {
  display: flex;
  flex-direction: row;
}
nav #left-nav {
  padding-left: 1rem;
}
nav #right-nav {
  justify-content: space-around;
  width: 45vw;
}
nav .fas {
  color: #f2f2f3;
  background-color: #1e1e1e;
}
nav #dropdown-button {
  color: #f2f2f3;
  font-size: 1.4rem;
  background-color: #1e1e1e;
  padding: 0 1rem;
  border: none;
  display: none;
}

/* -----------Phone Only Navbar (0px-599px)-----------*/
@media (max-width: 599px) {
  .nav {
    flex-direction: column;
  }
  .nav #left-nav {
    justify-content: space-between;
  }
  .nav #right-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .nav #dropdown-button {
    display: inline-block;
  }
  .nav #dropdown-button:hover {
    cursor: pointer;
  }
  /* this shows the menu, is gets added to div#right-nav when the side button is clicked */
  #right-nav.dropdown {
    display: flex;
    flex-direction: column;
    padding: 0.3rem 1rem 0.3rem 0;
  }
  #right-nav a {
    text-align: right;
    width: 100%;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.35rem;
  }
  #right-nav a:hover div {
    color: #43b1b9;
  }
}
/* ______________________________________________________________________ */
/* ---------------------- BEGIN INTRO SECTION --------------------*/
#introduction-section {
  padding-top: 3rem;
  transition: height 0.4s linear;
  height: 29rem;
  color: #242423;
  background-color: #1e1e1e;
  /*This is a fallback if background-image doesn't load */
  background-image: url("./images/background3a.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

/* This is a container holding the circle and its text */
.intro-text-wrapper {
  transition: height 0.4s linear;
  transition: width 0.4s linear;
  transition: margin 0.4s linear;
  height: 30rem;
  width: 29rem;
  padding: 10px;
  margin-top: 8rem;
  margin-left: 8rem;
  border-radius: 100%; /*This makes it a circle*/
  display: block;
  background-color: #f39c74;
  box-shadow: rgba(0, 0, 0, 0.39) 0px 2px 8px;
  /* other colors I considered were #f6b597;,#f8b08f;, and #242423; */
}

#introduction-section h1 {
  margin-top: 9rem;
  font-size: 1.8rem;
}

#introduction-section h2 {
  font-size: 1.4rem;
  margin: 1.5rem;
}

/* -------------------------Phone Only (0px-599px)---------------------*/
@media (max-width: 599px) {
  #introduction-section {
    background-image: url("./images/background3smalld.jpg");
  }
  #introduction-section .intro-text-wrapper {
    transition: margin 0.4s linear;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vw;
    margin-bottom: 12vw;
    transition: height 0.4s linear;
    transition: width 0.4s linear;
    height: 300px;
    width: 300px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  #introduction-section h1 {
    margin-top: 0;
    font-size: 1.2rem;
  }
  #introduction-section h2 {
    font-size: 0.9rem;
    margin: 0.5rem;
  }
}
/* Iphone 0-350px */
@media (max-width: 360px) {
  #introduction-section {
    transition: height 0.4s linear;
    height: 25rem;
  }
  #introduction-section .intro-text-wrapper {
    transition: height 0.4s linear;
    transition: width 0.4s linear;
    height: 75vw;
    width: 75vw;
    max-height: 250px;
    max-width: 250px;
    padding-top: 10px;
  }
  #introduction-section h1 {
    margin-top: 0;
    font-size: 0.95rem;
    font-weight: 600;
  }
  #introduction-section h2 {
    font-size: 0.875rem;
    margin: 0.5rem;
    font-weight: 450;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #introduction-section .intro-text-wrapper {
    transition: margin 0.4s linear;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  #introduction-section {
    position: relative;
    min-height: 29rem;
    height: 80vh;
  }
  #introduction-section .intro-text-wrapper {
    position: absolute;
    left: 8rem;
    bottom: -12rem;
  }
}
@media (min-width: 1600px) {
  #introduction-section {
    transition: height 0.4s linear;
    min-height: 47rem;
    height: 80vh;
    position: relative;
  }
  #introduction-section .intro-text-wrapper {
    position: absolute;
    height: 36rem;
    width: 36rem;
    left: 10vw;
    bottom: -14rem;
  }
  #introduction-section h1 {
    margin-top: 11rem;
    font-size: 2.2rem;
  }
  #introduction-section h2 {
    font-size: 1.5rem;
  }
}
/*-------------------------END INTRO SECTION------------------------- */
/* __________________________________________________________________ */
/* __________________________________________________________________*/
/* ----------------------BEGIN PORTFOLIO SECTION----------------------------- */
#portfolio-section {
  padding-bottom: 5rem;
  padding-top: 5rem;
  background-color: #1e1e1e;
  color: #f2f2f3;
  text-align: center;
}

#portfolio-section h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 3rem;
  font-weight: 450;
  padding: 5px;
  margin: 0;
}

/* ------------------------------- */
/* This is the corners on the portfolio h2 */
.portfolio-header-wrapper,
.about-header-wrapper {
  position: relative;
  display: inline-block;
  margin-top: 6rem;
  margin-bottom: 3rem;
  padding: 10px 15px;
}

.top,
.bottom {
  position: absolute;
  width: 30px;
  height: 30px;
}

.top {
  top: 0;
  border-top: 6px solid #050505;
}

.bottom {
  bottom: 0;
  border-bottom: 6px solid #050505;
}

.left {
  left: 0;
  border-left: 6px solid #050505;
}

.right {
  right: 0;
  border-right: 6px solid #050505;
}

/* End of corner styling*/
/* ------------------ */
/* sets the size of the grid and centers it */
.grid-wrapper {
  /* width: 90vw; */
  width: 93.5%;
  margin-left: auto;
  margin-right: auto;
}

/* Setting grid and divisions of grid */
.portfolio-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "one two three" "four five six";
  grid-gap: 30px;
  justify-items: center;
}
.portfolio-grid .grid-item-1 {
  grid-area: one;
}
.portfolio-grid .grid-item-2 {
  grid-area: two;
}
.portfolio-grid .grid-item-3 {
  grid-area: three;
}
.portfolio-grid .grid-item-4 {
  grid-area: four;
}
.portfolio-grid .grid-item-5 {
  grid-area: five;
}
.portfolio-grid .grid-item-6 {
  grid-area: six;
}

/* makes each grid item clickable anywhere  */
.portfolio-grid a {
  text-decoration: none;
  color: #f2f2f3;
}

/* This stylizes the project cells */
.portfolio-example {
  text-align: center;
  padding: 1.3rem 1.3rem 1.5rem 1.3rem;
  background-color: #333333;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.39) 0px 2px 8px;
}

.portfolio-example img {
  width: 100%;
  height: 11rem;
  border-radius: 8px;
}

.portfolio-example img.show-on-hover {
  display: none;
}

.portfolio-example:hover img.hide-on-hover {
  display: none;
}
.portfolio-example:hover img.show-on-hover {
  display: inline-block;
}

.portfolio-example p.tags {
  text-align: center;
  color: #43b1b9;
  padding-top: 1rem;
}

.portfolio-example h3 {
  padding: 1rem;
  font-weight: 400;
  font-size: 1.5rem;
}

.portfolio-grid a:hover h3 {
  color: #f39c74;
}

.portfolio-example p {
  text-align: left;
  line-height: 1.3;
}

@media (max-width: 599px) {
  #portfolio-section {
    padding-top: 1rem;
  }
  #portfolio-section .portfolio-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "one" "two" "three" "four" "five" "six";
  }
  #portfolio-section .portfolio-grid .grid-item-1 {
    grid-area: one;
  }
  #portfolio-section .portfolio-grid .grid-item-2 {
    grid-area: two;
  }
  #portfolio-section .portfolio-grid .grid-item-3 {
    grid-area: three;
  }
  #portfolio-section .portfolio-grid .grid-item-4 {
    grid-area: four;
  }
  #portfolio-section .portfolio-grid .grid-item-5 {
    grid-area: five;
  }
  #portfolio-section .portfolio-grid .grid-item-6 {
    grid-area: six;
  }
  #portfolio-section .portfolio-example {
    padding-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }
  #portfolio-section .portfolio-example img {
    width: 100%;
    height: 10rem;
  }
  #portfolio-section .portfolio-example p.tags {
    text-align: center;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #portfolio-section .portfolio-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "one two" "three four" "five six";
  }
  #portfolio-section .portfolio-grid .grid-item-1 {
    grid-area: one;
  }
  #portfolio-section .portfolio-grid .grid-item-2 {
    grid-area: two;
  }
  #portfolio-section .portfolio-grid .grid-item-3 {
    grid-area: three;
  }
  #portfolio-section .portfolio-grid .grid-item-4 {
    grid-area: four;
  }
  #portfolio-section .portfolio-grid .grid-item-5 {
    grid-area: five;
  }
  #portfolio-section .portfolio-grid .grid-item-6 {
    grid-area: six;
  }
  #portfolio-section .portfolio-example {
    transition: width 0.4s linear;
    max-width: 300px;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #portfolio-section .portfolio-example {
    max-width: 300px;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  #portfolio-section {
    position: relative;
  }
  #portfolio-section .portfolio-example {
    transition: width 0.4s linear;
    max-width: 350px;
  }
}
@media (min-width: 1600px) {
  #portfolio-section {
    position: relative;
  }
  #portfolio-section .portfolio-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 40px;
    grid-template-areas: "one two three four" "five six . .";
  }
  #portfolio-section .portfolio-grid .grid-item-1 {
    grid-area: one;
  }
  #portfolio-section .portfolio-grid .grid-item-2 {
    grid-area: two;
  }
  #portfolio-section .portfolio-grid .grid-item-3 {
    grid-area: three;
  }
  #portfolio-section .portfolio-grid .grid-item-4 {
    grid-area: four;
  }
  #portfolio-section .portfolio-grid .grid-item-5 {
    grid-area: five;
  }
  #portfolio-section .portfolio-grid .grid-item-6 {
    grid-area: six;
  }
  #portfolio-section p {
    font-size: 1.2rem;
  }
}
/*------------------------------------------------- Contact Me Section ----------------------------------------------------------------------------*/
#contact-section {
  padding-bottom: 23.28px;
  /*This makes it so the bottom corners don't move when the links are hovered */
  background-color: #f8f8f9;
  text-align: center;
  color: #242423;
  font-size: 1.5rem;
}

/* Corners for contact */
.contact-section-wrapper {
  position: relative;
  display: inline-block;
  /* width: 70vw; */
  transition: width 0.4s linear;
  width: 72.5%;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding: 2rem 1rem;
}

.contact-top,
.contact-bottom {
  position: absolute;
  width: 50px;
  height: 50px;
}

/* #e34815; */
.contact-top {
  top: 0;
  border-top: 6px solid #e35615;
}

.contact-bottom {
  bottom: 0;
  border-bottom: 6px solid #e35615;
}

.contact-left {
  left: 0;
  border-left: 6px solid #e35615;
}

.contact-right {
  right: 0;
  border-right: 6px solid #e35615;
}

#contact-section h2 {
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 1rem;
}

/* the row of contact links */
.link-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
  margin: 1.3rem auto 2rem auto;
}

#contact-section a {
  text-decoration: none;
  color: #1e1e1e;
  font-size: 1.2rem;
  margin-bottom: 0.35rem;
}
#contact-section .fas,
#contact-section .fab {
  color: #1e1e1e;
  background-color: #f8f8f9;
}
#contact-section a:active,
#contact-section a:active .fab,
#contact-section a:active .fas {
  color: #1e1e1e;
  font-size: 1.2rem;
  margin-bottom: 0.35rem;
}
#contact-section a:hover,
#contact-section a:hover .fab,
#contact-section a:hover .fas {
  color: #43b1b9;
  margin-bottom: 0;
}

@media (max-width: 599px) {
  #contact-section .contact-section-wrapper {
    width: 93.5%;
  }
  #contact-section p {
    font-size: 1.2rem;
  }
  #contact-section .link-box {
    width: 70%;
    margin-top: 1.5rem;
  }
  #contact-section a {
    font-size: 0.8rem;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #contact-section p {
    font-size: 1.3rem;
  }
  #contact-section .link-box {
    transition: margin 0.4s linear;
    margin-top: 1.5rem;
    width: 60%;
  }
  #contact-section .contact-section-wrapper {
    width: 93.5%;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #contact-section .contact-section-wrapper {
    transition: width 0.4s linear;
    width: 93.5%;
  }
  #contact-section .link-box {
    width: 40%;
  }
}
@media (min-width: 1600px) {
  #contact-section .link-box {
    width: 35%;
  }
  /* This keeps the social media links from expanding too wide. */
}
#about-section {
  padding-bottom: 5rem;
  padding-top: 5rem;
  background-color: #1e1e1e;
  color: #f2f2f3;
  color: rgba(242, 242, 243, 0.7803921569);
  text-align: center;
}

#about-section h2 {
  font-family: "Source Sans Pro";
  font-size: 3rem;
  font-weight: 450;
  padding: 5px;
  margin: 0;
}

.about-description {
  text-align: left;
  width: 72.5%;
  padding: 2rem 1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.about-description .description-block-2 {
  margin-top: 2rem;
}
.about-description h3 {
  font-size: 1.5rem;
  padding: 0.3rem;
  padding-left: 0;
  border-bottom: #e35615 solid 2px;
  margin-bottom: 0.5rem;
}
.about-description p {
  font-size: 1.2rem;
}

@media (max-width: 599px) {
  .about-description {
    width: 93.5%;
  }
  .about-description p {
    font-size: 1rem;
  }
}
@media (max-width: 599px) {
  #about-section {
    padding-top: 1rem;
  }
}
/*---------------------------------------------------------- Footer section-------------------------------------------------------- */
#footer {
  position: relative;
  background-color: #1e1e1e;
  height: 7rem;
  color: #8d8d8f;
}
#footer .image-reference {
  text-align: right;
  padding: 2rem;
  line-height: 1.4;
}
#footer a {
  text-decoration: none;
  color: #8d8d8f;
}
#footer i {
  font-size: 0.7rem;
}

@media (max-width: 599px) {
  #footer .image-reference {
    font-size: 0.8rem;
  }
}
@media (max-width: 360px) {
  #footer .image-reference {
    font-size: 0.8rem;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #contact-section .contact-section-wrapper {
    transition: width 0.4s linear;
    width: 93.5%;
  }
  #contact-section .link-box {
    width: 40%;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  #footer .image-reference {
    font-size: 1rem;
  }
}/*# sourceMappingURL=App.css.map */